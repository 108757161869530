import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#cc8b65',
    },
    secondary: {
      main: '#013328',
    },
    background: {
      default: '#013328',
      paper: '#e3dcd2',
    },
    text: {
      primary: '#100c0d',
      secondary: '#100c0d',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          backgroundColor: '#cc8b65',
          '&:hover': {
            backgroundColor: '#b87a54',
          },
          '&.learn-more': {
            backgroundColor: '#e3dcd2',
            color: '#100c0d',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#cc8b65',
            },
            '&:hover fieldset': {
              borderColor: '#b87a54',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#cc8b65',
            },
          },
        },
      },
    },
  },
  spacing: 4,
});

export default theme;
