import axios from 'axios';

const API_BASE_URL = 'https://api.podthis.co';

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const listPodcasts = async () => {
  try {
    const response = await api.get('/list');
    return response.data;
  } catch (error) {
    console.error('Error fetching podcasts:', error);
    throw error;
  }
};

export const generatePodcast = async (topic) => {
  try {
    const response = await api.post('/generate-podcast', { topic });
    return response.data;
  } catch (error) {
    console.error('Error generating podcast:', error);
    throw error;
  }
};
