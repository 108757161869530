import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { Create, Psychology, PlayCircleOutline } from '@mui/icons-material';

const steps = [
  { icon: <Psychology />, title: 'Think It', description: 'Choose any topic you can imagine.' },
  { icon: <Create />, title: 'Type It', description: 'Enter your idea into the input box.' },
  { icon: <PlayCircleOutline />, title: 'Hear it', description: 'Click "Generate Podcast" and let AI do the rest!' },
];

const HowItWorks = () => {
  return (
    <Box sx={{ pt: 8, pb: 0 }}>
      <Grid container spacing={4} justifyContent="center">
        {steps.map((step, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Paper elevation={3} sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#cc8b65', }}>
              {React.cloneElement(step.icon, { sx: { color: '#cc8b65', fontSize: 40 } })}
              <Typography variant="h6" component="h3" sx={{ mt: 2, color: '#cc8b65', }}>
                {step.title}
              </Typography>
              <Typography variant="body1" align="center" sx={{ mt: 1, color: '#cc8b65', }}>
                {step.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HowItWorks;
