import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const PodcastCard = ({ podcast, onClick }) => {
  return (
    <Card onClick={onClick} sx={{ cursor: 'pointer', height: '200px' }}>
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          sx={{
            color: '#cc8b65',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            mb: 1
          }}
        >
          {podcast.title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {podcast.summary}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PodcastCard;
