import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactAudioPlayer from 'react-audio-player';
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton, TwitterIcon, FacebookIcon, LinkedinIcon } from 'react-share';

const PodcastModal = ({ open, onClose, podcast }) => {
  if (!podcast) return null;

  console.log('Podcast object:', podcast);
  console.log('Chapter titles:', podcast.chapter_titles);
  console.log('Tags:', podcast.tags);

  const shareUrl = `https://podthis.co/podcast/${podcast.id}`;
  const shareTitle = `Check out this podcast: ${podcast.title || 'Untitled'}`;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#cc8b65',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle sx={{ color: '#cc8b65' }}>{podcast.title || 'Untitled Podcast'}</DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom sx={{ color: '#cc8b65' }}>
          Summary
        </Typography>
        <Typography paragraph>{podcast.summary || 'No summary available'}</Typography>

        <Typography variant="h6" gutterBottom sx={{ color: '#cc8b65' }}>
          Audio Player
        </Typography>
        <Box sx={{ mb: 2 }}>
          <ReactAudioPlayer
            src={podcast.filename ? `https://api.podthis.co/podcasts/${podcast.filename}` : ''}
            controls
            style={{ width: '100%' }}
          />
        </Box>

        <Typography variant="body2">
          Length: {podcast.length ? `${podcast.length} minutes` : 'Unknown'}
        </Typography>
        <Typography variant="body2">
          Created by: {podcast.created_by || 'Unknown'}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: '#cc8b65', mt: 2 }}>
          Tags
        </Typography>
        <Typography variant="body2">
          {Array.isArray(podcast.tags) ? podcast.tags.join(', ') : 'No tags available'}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ color: '#cc8b65', mt: 2 }}>
          Share this podcast
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TwitterShareButton url={shareUrl} title={shareTitle}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <FacebookShareButton url={shareUrl} quote={shareTitle}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <LinkedinShareButton url={shareUrl} title={shareTitle}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PodcastModal;
