import React, { useState, useMemo } from 'react';
import { Grid, Typography, CircularProgress, Paper } from '@mui/material';
import usePodcasts from '../hooks/usePodcasts';
import PodcastModal from './PodcastModal';
import SearchBar from './SearchBar';
import PodcastCard from './PodcastCard';

const PodcastGrid = () => {
  const { podcasts, loading, error } = usePodcasts();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPodcast, setSelectedPodcast] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const filteredPodcasts = useMemo(() => {
    return podcasts.filter((podcast) =>
      (podcast.title && podcast.title.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (podcast.summary && podcast.summary.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (Array.isArray(podcast.tags) && podcast.tags.some((tag) => tag.toLowerCase().includes(searchQuery.toLowerCase())))
    );
  }, [podcasts, searchQuery]);

  const handlePodcastClick = (podcast) => {
    setSelectedPodcast(podcast);
    setOpenModal(true);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </Paper>
      <Grid container spacing={3}>
        {filteredPodcasts.map((podcast) => (
          <Grid item xs={12} sm={6} md={4} key={podcast.id}>
            <PodcastCard podcast={podcast} onClick={() => handlePodcastClick(podcast)} />
          </Grid>
        ))}
      </Grid>
      {filteredPodcasts.length === 0 && (
        <Typography variant="h6" sx={{ mt: 2 }}>
          No podcasts found matching your search.
        </Typography>
      )}
      <PodcastModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        podcast={selectedPodcast}
      />
    </>
  );
};

export default PodcastGrid;
