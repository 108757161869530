import { useState, useEffect } from 'react';
import { listPodcasts, generatePodcast } from '../services/api';

const usePodcasts = () => {
  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPodcasts();
  }, []);

  const fetchPodcasts = async () => {
    setLoading(true);
    try {
      const data = await listPodcasts();
      setPodcasts(data);
    } catch (err) {
      setError('Failed to fetch podcasts');
    } finally {
      setLoading(false);
    }
  };

  const createPodcast = async (topic) => {
    setLoading(true);
    try {
      const newPodcast = await generatePodcast(topic);
      setPodcasts([newPodcast, ...podcasts]);
      return newPodcast;
    } catch (err) {
      setError('Failed to generate podcast');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { podcasts, loading, error, fetchPodcasts, createPodcast };
};

export default usePodcasts;
