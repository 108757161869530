import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import HowItWorks from './components/HowItWorks';
import PodcastGrid from './components/PodcastGrid';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Header />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <HowItWorks />
              <Box sx={{ my: 8 }}>
                <PodcastGrid />
              </Box>
              {/* <Box sx={{ my: 8 }}>
                <FeaturedPodcasts />
              </Box> */}
              <Box sx={{ my: 8 }}>
                <CallToAction />
              </Box>
            </>
          } />
          {/* Add more routes here if needed */}
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
