import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const CallToAction = () => {
  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
        color: '#e3dcd2',
        py: 6,
        textAlign: 'center',
        mt: 4,
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom>
        Ready to share your story?
      </Typography>
      <Typography variant="h6" paragraph>
        Generate your podcast now!
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={() => {
          // Scroll to podcast form
          const form = document.querySelector('form');
          form.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        Get Started
      </Button>
    </Box>
  );
};

export default CallToAction;
