import React, { useState } from 'react';
import { Box, TextField, Button, Typography, CircularProgress } from '@mui/material';
import usePodcasts from '../hooks/usePodcasts';
import PodcastModal from './PodcastModal';

const PodcastForm = () => {
  const [topic, setTopic] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [generatedPodcast, setGeneratedPodcast] = useState(null);
  const { createPodcast, loading, error } = usePodcasts();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (topic.trim()) {
      try {
        const newPodcast = await createPodcast(topic);
        setGeneratedPodcast(newPodcast);
        setOpenModal(true);
      } catch (err) {
        console.error('Failed to generate podcast:', err);
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
      <TextField
        fullWidth
        label="Enter your podcast topic"
        variant="outlined"
        value={topic}
        onChange={(e) => setTopic(e.target.value)}
        disabled={loading}
        sx={{ mb: 2 }}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={loading || !topic.trim()}
        sx={{ color: '#e3dcd2' }}
      >
        {loading ? <CircularProgress size={24} /> : 'Generate Podcast'}
      </Button>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      <PodcastModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        podcast={generatedPodcast}
      />
    </Box>
  );
};

export default PodcastForm;
