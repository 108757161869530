import React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 16,
        right: 16,
        zIndex: 1000,
        padding: '8px',
        borderRadius: '4px',
        backgroundColor: '#013328',
      }}
    >
      {['Home', 'About', 'FAQ'].map((text, index) => (
        <Button
          key={text}
          color="inherit"
          component={Link}
          to={index === 0 ? '/' : `/${text.toLowerCase()}`}
          sx={{
            color: '#cc8b65',
            backgroundColor: '#013328',
            '&:hover': {
              backgroundColor: '#024a3b',
            },
            mr: index < 2 ? 1 : 0,
          }}
        >
          {text}
        </Button>
      ))}
    </Box>
  );
};

export default Header;
